<template>
	<div>
		<div class="optionalSheet" v-if="windowWidth>=1200">
			<div class="os_title">
				<div class="os_tt_text">{{$t('aluminumSubstrate.optionalSheet.title')}}</div>
				<div class="os_tt_text2">{{$t('aluminumSubstrate.optionalSheet.title')}}</div>
			</div>

			<div class="os_brand">
				<el-image class="os_br_image" :src="$t('aluminumSubstrate.optionalSheet.content1.img')" :fit="'cover'"></el-image>
				<div class="os_br_div">
					<div class="os_br_di_title">{{$t('aluminumSubstrate.optionalSheet.content1.title')}}</div>
					<dl class="os_br_di_content">
						<dt class="os_br_di_ct_lable">{{$t('aluminumSubstrate.optionalSheet.content1.name1')}}</dt>
						<dd class="os_br_di_ct_text">{{$t('aluminumSubstrate.optionalSheet.content1.text1')}}</dd>
					</dl>
					<dl class="os_br_di_content">
						<dt class="os_br_di_ct_lable">{{$t('aluminumSubstrate.optionalSheet.content1.name2')}}</dt>
						<dd class="os_br_di_ct_text">{{$t('aluminumSubstrate.optionalSheet.content1.text2')}}</dd>
					</dl>
				</div>
			</div>
			<div class="os_aluminumSubstrate">
				<el-image class="os_as_image" :src="$t('aluminumSubstrate.optionalSheet.content2.img')" :fit="'cover'"></el-image>
				<div class="os_as_div">
					<div class="os_as_di_title">{{$t('aluminumSubstrate.optionalSheet.content2.title')}}</div>
					<dl class="os_as_di_ti_content">
						<dt class="os_as_di_ti_ct_lable">{{$t('aluminumSubstrate.optionalSheet.content2.name1')}}</dt>
						<dd class="os_as_di_ti_ct_text">{{$t('aluminumSubstrate.optionalSheet.content2.text1')}}</dd>
					</dl>
					<dl class="os_as_di_ti_content">
						<dt class="os_as_di_ti_ct_lable">{{$t('aluminumSubstrate.optionalSheet.content2.name2')}}</dt>
						<dd class="os_as_di_ti_ct_text">{{$t('aluminumSubstrate.optionalSheet.content2.text2')}}</dd>
					</dl>
				</div>
			</div>

			<div class="os_plate">
				<div class="os_pl_div" v-for="(item,index) in $t('aluminumSubstrate.optionalSheet.list')" :key="index">
					<div class="os_pl_di_imageDiv">
						<img class="os_pl_di_id_img" :src="item.img" />
					</div>

					<div class="os_pl_di_contentDiv">
						<div class="os_pl_di_cd_title">{{item.title}}</div>
						<dl class="os_pl_di_cd_dl">
							<dd v-for="(item2,index2) in item.diList" :key="index2">
								{{item2}}
							</dd>
						</dl>
						<div class="os_pl_di_cd_pricing" @click="jump">
							{{$t('aluminumSubstrate.optionalSheet.button')}}
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="optionalSheet2" v-if="windowWidth<=1199">
			<div class="os_title">
				<div class="os_tt_text">{{$t('aluminumSubstrate.optionalSheet.title')}}</div>
				<div class="os_tt_text2">{{$t('aluminumSubstrate.optionalSheet.title')}}</div>
			</div>
		
			<div class="os_brand">
				<div class="os_br_div">
					<el-image class="os_br_image" :src="$t('aluminumSubstrate.optionalSheet.content1.img')" :fit="'cover'"></el-image>
					<div style="padding: 10px;">
						<div class="os_br_di_title">{{$t('aluminumSubstrate.optionalSheet.content1.title')}}</div>
						<dl class="os_br_di_content">
							<dt class="os_br_di_ct_lable">{{$t('aluminumSubstrate.optionalSheet.content1.name1')}}</dt>
							<div class="os_br_di_ct_text">{{$t('aluminumSubstrate.optionalSheet.content1.text1')}}</div>
						</dl>
						<dl class="os_br_di_content">
							<dt class="os_br_di_ct_lable">{{$t('aluminumSubstrate.optionalSheet.content1.name2')}}</dt>
							<div class="os_br_di_ct_text">{{$t('aluminumSubstrate.optionalSheet.content1.text2')}}</div>
						</dl>
					</div>
				</div>
			</div>
			
			<div class="os_aluminumSubstrate">
				<div class="os_as_div">
					<el-image class="os_as_image" :src="$t('aluminumSubstrate.optionalSheet.content2.img')" :fit="'cover'"></el-image>
					<div style="padding: 10px;">
						<div class="os_as_di_title">{{$t('aluminumSubstrate.optionalSheet.content2.title')}}</div>
						<dl class="os_as_di_ti_content">
							<dt class="os_as_di_ti_ct_lable">{{$t('aluminumSubstrate.optionalSheet.content2.name1')}}</dt>
							<div class="os_as_di_ti_ct_text">{{$t('aluminumSubstrate.optionalSheet.content2.text1')}}</div>
						</dl>
						<dl class="os_as_di_ti_content">
							<dt class="os_as_di_ti_ct_lable">{{$t('aluminumSubstrate.optionalSheet.content2.name2')}}</dt>
							<div class="os_as_di_ti_ct_text">{{$t('aluminumSubstrate.optionalSheet.content2.text2')}}</div>
						</dl>
					</div>
				</div>
			</div>
		
			<div class="os_plate">
				<div class="os_pl_div" v-for="(item,index) in $t('aluminumSubstrate.optionalSheet.list')" :key="index">
					<div class="os_pl_di_imageDiv">
						<img class="os_pl_di_id_img" :src="item.img" />
					</div>
		
					<div class="os_pl_di_contentDiv">
						<div>
							<div class="os_pl_di_cd_title">{{item.title}}</div>
							<dl class="os_pl_di_cd_dl">
								<dd v-for="(item2,index2) in item.diList" :key="index2">
									{{item2}}
								</dd>
							</dl>
							<div class="os_pl_di_cd_pricing" @click="jump">
								{{$t('aluminumSubstrate.optionalSheet.button')}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//-----------------路由跳转-----------------------
			jump:function(){
				this.$router.push({
					path: '/aboutUs/aboutUs',
				})
				this.$store.dispatch("setTopTabsIndex", {
				    topTabsIndex: 0,
				});
			}
		},
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.optionalSheet {
			padding: 40px 200px;
			background: #333333;
		
			.os_title {
				text-align: center;
				position: relative;
		
				.os_tt_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
					z-index: 3;
					position: relative;
				}
		
				.os_tt_text2 {
					font-size: 40px;
					color: #3c3c3c;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.os_brand {
				width: 100%;
				display: flex;
				position: relative;
				margin-top: 70px;
		
				.os_br_image {
					position: absolute;
					width: 18vw;
					height: 100%;
					left: 0;
					top: 0;
				}
		
				.os_br_div {
					padding: 1vw;
					background: #fff;
					width: 100%;
					padding-left: 20vw;
		
					.os_br_di_title {
						font-size: 1vw;
						font-weight: bold;
					}
		
					.os_br_di_content {
						display: flex;
						align-items: center;
						margin-top: 20px;
		
						.os_br_di_ct_lable {
							font-size: 18px;
							color: #f5f5f5;
							min-width: 80px;
							height: 30px;
							padding: 0 10px;
							box-sizing: border-box;
							background-color: red;
							border-radius: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							font-weight: 700;
						}
		
						.os_br_di_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_aluminumSubstrate {
				width: 100%;
				display: flex;
				position: relative;
				margin-top: 5px;
		
				.os_as_image {
					position: absolute;
					width: 18vw;
					height: 100%;
					right: 0;
					top: 0;
				}
		
				.os_as_div {
					padding: 1vw;
					background: #fff;
					width: 100%;
					padding-right: 20vw;
		
					.os_as_di_title {
						font-size: 1vw;
						font-weight: bold;
					}
		
					.os_as_di_ti_content {
						display: flex;
						align-items: center;
						margin-top: 20px;
		
						.os_as_di_ti_ct_lable {
							font-size: 18px;
							color: #f5f5f5;
							min-width: 80px;
							height: 30px;
							padding: 0 20px;
							box-sizing: border-box;
							background-color: red;
							border-radius: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							font-weight: 700;
						}
		
						.os_as_di_ti_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_plate {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
		
				.os_pl_div {
					margin-top: 20px;
					width: 49%;
					background-color: #ffffff;
					display: flex;
					align-items: center;
					padding: 10px 20px;
					box-sizing: border-box;
					transition: all 0.3s;
					position: relative;
		
					.os_pl_di_imageDiv {
						width: 256px;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
		
						.os_pl_di_id_img {
							border: none;
							vertical-align: middle;
						}
					}
		
					.os_pl_di_contentDiv {
						margin-left: 34px;
						align-self: baseline;
		
						.os_pl_di_cd_title {
							font-size: 20px;
							font-weight: 700;
							color: #383838;
							margin-bottom: 8px;
						}
		
						.os_pl_di_cd_dl {
							color: #666666;
							font-size: 0.8vw;
							line-height: 24px;
						}
		
						.os_pl_di_cd_pricing {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 150px;
							height: 30px;
							color: #666666;
							border: solid 1px #cbcbcb;
							font-size: 14px;
							margin-top: 14px;
						}
					}
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_title{
					color: #f44332;
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_pricing{
					color: #fff;
					border: solid 1px #f44332;
					background: #f44332;
				}
				
				.os_pl_div:hover:after{
					width: 100%;
				}
		
				.os_pl_div::after {
					display: block;
					content: '';
					width: 0;
					border-bottom: 2px solid #f44332;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.optionalSheet {
			padding: 40px 200px;
			background: #333333;
		
			.os_title {
				text-align: center;
				position: relative;
		
				.os_tt_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
					z-index: 3;
					position: relative;
				}
		
				.os_tt_text2 {
					font-size: 40px;
					color: #3c3c3c;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.os_brand {
				width: 100%;
				display: flex;
				position: relative;
				margin-top: 70px;
		
				.os_br_image {
					position: absolute;
					width: 18vw;
					height: 100%;
					left: 0;
					top: 0;
				}
		
				.os_br_div {
					padding: 1vw;
					background: #fff;
					width: 100%;
					padding-left: 20vw;
		
					.os_br_di_title {
						font-size: 1vw;
						font-weight: bold;
					}
		
					.os_br_di_content {
						display: flex;
						align-items: center;
						margin-top: 20px;
		
						.os_br_di_ct_lable {
							font-size: 18px;
							color: #f5f5f5;
							min-width: 80px;
							height: 30px;
							padding: 0 10px;
							box-sizing: border-box;
							background-color: red;
							border-radius: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							font-weight: 700;
						}
		
						.os_br_di_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_aluminumSubstrate {
				width: 100%;
				display: flex;
				position: relative;
				margin-top: 5px;
		
				.os_as_image {
					position: absolute;
					width: 18vw;
					height: 100%;
					right: 0;
					top: 0;
				}
		
				.os_as_div {
					padding: 1vw;
					background: #fff;
					width: 100%;
					padding-right: 20vw;
		
					.os_as_di_title {
						font-size: 1vw;
						font-weight: bold;
					}
		
					.os_as_di_ti_content {
						display: flex;
						align-items: center;
						margin-top: 20px;
		
						.os_as_di_ti_ct_lable {
							font-size: 18px;
							color: #f5f5f5;
							min-width: 80px;
							height: 30px;
							padding: 0 20px;
							box-sizing: border-box;
							background-color: red;
							border-radius: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							font-weight: 700;
						}
		
						.os_as_di_ti_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_plate {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
		
				.os_pl_div {
					margin-top: 20px;
					width: 49%;
					background-color: #ffffff;
					display: flex;
					align-items: center;
					padding: 10px 20px;
					box-sizing: border-box;
					transition: all 0.3s;
					position: relative;
		
					.os_pl_di_imageDiv {
						width: 256px;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
		
						.os_pl_di_id_img {
							border: none;
							vertical-align: middle;
						}
					}
		
					.os_pl_di_contentDiv {
						margin-left: 34px;
						align-self: baseline;
		
						.os_pl_di_cd_title {
							font-size: 20px;
							font-weight: 700;
							color: #383838;
							margin-bottom: 8px;
						}
		
						.os_pl_di_cd_dl {
							color: #666666;
							font-size: 0.8vw;
							line-height: 24px;
						}
		
						.os_pl_di_cd_pricing {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 150px;
							height: 30px;
							color: #666666;
							border: solid 1px #cbcbcb;
							font-size: 14px;
							margin-top: 14px;
						}
					}
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_title{
					color: #f44332;
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_pricing{
					color: #fff;
					border: solid 1px #f44332;
					background: #f44332;
				}
				
				.os_pl_div:hover:after{
					width: 100%;
				}
		
				.os_pl_div::after {
					display: block;
					content: '';
					width: 0;
					border-bottom: 2px solid #f44332;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.optionalSheet {
			padding: 40px 140px;
			background: #333333;
		
			.os_title {
				text-align: center;
				position: relative;
		
				.os_tt_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
					z-index: 3;
					position: relative;
				}
		
				.os_tt_text2 {
					font-size: 40px;
					color: #3c3c3c;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.os_brand {
				width: 100%;
				display: flex;
				position: relative;
				margin-top: 70px;
		
				.os_br_image {
					position: absolute;
					width: 18vw;
					height: 100%;
					left: 0;
					top: 0;
				}
		
				.os_br_div {
					padding: 1vw;
					background: #fff;
					width: 100%;
					padding-left: 20vw;
		
					.os_br_di_title {
						font-size: 1vw;
						font-weight: bold;
					}
		
					.os_br_di_content {
						display: flex;
						align-items: center;
						margin-top: 20px;
		
						.os_br_di_ct_lable {
							font-size: 18px;
							color: #f5f5f5;
							min-width: 80px;
							height: 30px;
							padding: 0 10px;
							box-sizing: border-box;
							background-color: red;
							border-radius: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							font-weight: 700;
						}
		
						.os_br_di_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_aluminumSubstrate {
				width: 100%;
				display: flex;
				position: relative;
				margin-top: 5px;
		
				.os_as_image {
					position: absolute;
					width: 18vw;
					height: 100%;
					right: 0;
					top: 0;
				}
		
				.os_as_div {
					padding: 1vw;
					background: #fff;
					width: 100%;
					padding-right: 20vw;
		
					.os_as_di_title {
						font-size: 1vw;
						font-weight: bold;
					}
		
					.os_as_di_ti_content {
						display: flex;
						align-items: center;
						margin-top: 20px;
		
						.os_as_di_ti_ct_lable {
							font-size: 18px;
							color: #f5f5f5;
							min-width: 80px;
							height: 30px;
							padding: 0 20px;
							box-sizing: border-box;
							background-color: red;
							border-radius: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							font-weight: 700;
						}
		
						.os_as_di_ti_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_plate {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
		
				.os_pl_div {
					margin-top: 20px;
					width: 49%;
					background-color: #ffffff;
					display: flex;
					align-items: center;
					padding: 10px 20px;
					box-sizing: border-box;
					transition: all 0.3s;
					position: relative;
		
					.os_pl_di_imageDiv {
						width: 256px;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
		
						.os_pl_di_id_img {
							border: none;
							vertical-align: middle;
						}
					}
		
					.os_pl_di_contentDiv {
						margin-left: 34px;
						align-self: baseline;
		
						.os_pl_di_cd_title {
							font-size: 20px;
							font-weight: 700;
							color: #383838;
							margin-bottom: 8px;
						}
		
						.os_pl_di_cd_dl {
							color: #666666;
							font-size: 0.8vw;
							line-height: 24px;
						}
		
						.os_pl_di_cd_pricing {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 150px;
							height: 30px;
							color: #666666;
							border: solid 1px #cbcbcb;
							font-size: 14px;
							margin-top: 14px;
						}
					}
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_title{
					color: #f44332;
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_pricing{
					color: #fff;
					border: solid 1px #f44332;
					background: #f44332;
				}
				
				.os_pl_div:hover:after{
					width: 100%;
				}
		
				.os_pl_div::after {
					display: block;
					content: '';
					width: 0;
					border-bottom: 2px solid #f44332;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.optionalSheet {
			padding: 40px 30px;
			background: #333333;
		
			.os_title {
				text-align: center;
				position: relative;
		
				.os_tt_text {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
					z-index: 3;
					position: relative;
				}
		
				.os_tt_text2 {
					font-size: 40px;
					color: #3c3c3c;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.os_brand {
				width: 100%;
				display: flex;
				position: relative;
				margin-top: 70px;
		
				.os_br_image {
					position: absolute;
					width: 18vw;
					height: 100%;
					left: 0;
					top: 0;
				}
		
				.os_br_div {
					padding: 1vw;
					background: #fff;
					width: 100%;
					padding-left: 20vw;
		
					.os_br_di_title {
						font-size: 1vw;
						font-weight: bold;
					}
		
					.os_br_di_content {
						display: flex;
						align-items: center;
						margin-top: 20px;
		
						.os_br_di_ct_lable {
							font-size: 18px;
							color: #f5f5f5;
							min-width: 80px;
							height: 30px;
							padding: 0 10px;
							box-sizing: border-box;
							background-color: red;
							border-radius: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							font-weight: 700;
						}
		
						.os_br_di_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_aluminumSubstrate {
				width: 100%;
				display: flex;
				position: relative;
				margin-top: 5px;
		
				.os_as_image {
					position: absolute;
					width: 18vw;
					height: 100%;
					right: 0;
					top: 0;
				}
		
				.os_as_div {
					padding: 1vw;
					background: #fff;
					width: 100%;
					padding-right: 20vw;
		
					.os_as_di_title {
						font-size: 1vw;
						font-weight: bold;
					}
		
					.os_as_di_ti_content {
						display: flex;
						align-items: center;
						margin-top: 20px;
		
						.os_as_di_ti_ct_lable {
							font-size: 18px;
							color: #f5f5f5;
							min-width: 80px;
							height: 30px;
							padding: 0 20px;
							box-sizing: border-box;
							background-color: red;
							border-radius: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 15px;
							font-weight: 700;
						}
		
						.os_as_di_ti_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_plate {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
		
				.os_pl_div {
					margin-top: 20px;
					width: 49%;
					background-color: #ffffff;
					display: flex;
					align-items: center;
					padding: 10px 20px;
					box-sizing: border-box;
					transition: all 0.3s;
					position: relative;
		
					.os_pl_di_imageDiv {
						width: 256px;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
		
						.os_pl_di_id_img {
							border: none;
							vertical-align: middle;
						}
					}
		
					.os_pl_di_contentDiv {
						margin-left: 34px;
						align-self: baseline;
		
						.os_pl_di_cd_title {
							font-size: 20px;
							font-weight: 700;
							color: #383838;
							margin-bottom: 8px;
						}
		
						.os_pl_di_cd_dl {
							color: #666666;
							font-size: 0.8vw;
							line-height: 24px;
						}
		
						.os_pl_di_cd_pricing {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 150px;
							height: 30px;
							color: #666666;
							border: solid 1px #cbcbcb;
							font-size: 14px;
							margin-top: 14px;
						}
					}
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_title{
					color: #f44332;
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_pricing{
					color: #fff;
					border: solid 1px #f44332;
					background: #f44332;
				}
				
				.os_pl_div:hover:after{
					width: 100%;
				}
		
				.os_pl_div::after {
					display: block;
					content: '';
					width: 0;
					border-bottom: 2px solid #f44332;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.optionalSheet2 {
			padding: 40px 30px;
			background: #333333;
		
			.os_title {
				text-align: center;
				position: relative;
		
				.os_tt_text {
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
					z-index: 3;
					position: relative;
				}
		
				.os_tt_text2 {
					font-size: 29px;
					color: #3c3c3c;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.os_brand {
				width: 100%;
				position: relative;
				margin-top: 70px;
		
				.os_br_div {
					background: #fff;
					width: 100%;
					
					.os_br_image {
						width: 100%;
					}
		
					.os_br_di_title {
						font-size: 16px;
						font-weight: bold;
					}
		
					.os_br_di_content {
						margin-top: 20px;
		
						.os_br_di_ct_lable {
							font-size: 16px;
							color: #d1102d;
							font-weight: 700;
						}
		
						.os_br_di_ct_text {
							font-size: 16px;
						}
					}
				}
			}
		
			.os_aluminumSubstrate {
				width: 100%;
				position: relative;
				margin-top: 5px;
		
				.os_as_div {
					background: #fff;
					width: 100%;
					
					.os_as_image {
						width: 100%;
					}
		
					.os_as_di_title {
						font-size: 16px;
						font-weight: bold;
					}
		
					.os_as_di_ti_content {
						margin-top: 20px;
		
						.os_as_di_ti_ct_lable {
							font-size: 16px;
							color: #d1102d;
							font-weight: bold;
						}
		
						.os_as_di_ti_ct_text {
							font-size: 16px;
						}
					}
				}
			}
		
			.os_plate {
				width: 100%;
		
				.os_pl_div {
					margin-top: 20px;
					background-color: #ffffff;
					padding: 10px 20px;
					box-sizing: border-box;
					transition: all 0.3s;
					position: relative;
		
					.os_pl_di_imageDiv {
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
		
						.os_pl_di_id_img {
							border: none;
							vertical-align: middle;
						}
					}
		
					.os_pl_di_contentDiv {
						display: flex;
						text-align: center;
						justify-content: center;
		
						.os_pl_di_cd_title {
							font-size: 21px;
							font-weight: 700;
							color: #383838;
							margin-bottom: 8px;
						}
		
						.os_pl_di_cd_dl {
							color: #666666;
							font-size: 12px;
							line-height: 24px;
						}
		
						.os_pl_di_cd_pricing {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 30px;
							color: #666666;
							border: solid 1px #cbcbcb;
							font-size: 16px;
							margin-top: 14px;
						}
					}
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_title{
					color: #f44332;
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_pricing{
					color: #fff;
					border: solid 1px #f44332;
					background: #f44332;
				}
				
				.os_pl_div:hover:after{
					width: 100%;
				}
		
				.os_pl_div::after {
					display: block;
					content: '';
					width: 0;
					border-bottom: 2px solid #f44332;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.optionalSheet2 {
			padding: 40px 30px;
			background: #333333;
		
			.os_title {
				text-align: center;
				position: relative;
		
				.os_tt_text {
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
					z-index: 3;
					position: relative;
				}
		
				.os_tt_text2 {
					font-size: 29px;
					color: #3c3c3c;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.os_brand {
				width: 100%;
				position: relative;
				margin-top: 70px;
					
				.os_br_div {
					background: #fff;
					width: 100%;
					
					.os_br_image {
						width: 100%;
					}
					
					.os_br_di_title {
						font-size: 16px;
						font-weight: bold;
					}
					
					.os_br_di_content {
						margin-top: 20px;
					
						.os_br_di_ct_lable {
							font-size: 18px;
							color: #d1102d;
							font-weight: 700;
						}
					
						.os_br_di_ct_text {
							font-size: 18px;
						}
					}
				}
			}
		
			.os_aluminumSubstrate {
				width: 100%;
				position: relative;
				margin-top: 5px;
		
				.os_as_div {
					background: #fff;
					width: 100%;
					
					.os_as_image {
						width: 100%;
					}
		
					.os_as_di_title {
						font-size: 16px;
						font-weight: bold;
					}
		
					.os_as_di_ti_content {
						margin-top: 20px;
		
						.os_as_di_ti_ct_lable {
							font-size: 16px;
							color: #d1102d;
							font-weight: bold;
						}
		
						.os_as_di_ti_ct_text {
							font-size: 16px;
						}
					}
				}
			}
		
			.os_plate {
				width: 100%;
		
				.os_pl_div {
					margin-top: 20px;
					background-color: #ffffff;
					padding: 10px 20px;
					box-sizing: border-box;
					transition: all 0.3s;
					position: relative;
		
					.os_pl_di_imageDiv {
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
		
						.os_pl_di_id_img {
							width: 60vw;
							padding-bottom: 20px;
						}
					}
		
					.os_pl_di_contentDiv {
						display: flex;
						text-align: center;
						justify-content: center;
		
						.os_pl_di_cd_title {
							font-size: 21px;
							font-weight: 700;
							color: #383838;
							margin-bottom: 8px;
						}
		
						.os_pl_di_cd_dl {
							color: #666666;
							font-size:16px;
							line-height: 24px;
						}
		
						.os_pl_di_cd_pricing {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 30px;
							color: #666666;
							border: solid 1px #cbcbcb;
							font-size: 16px;
							margin-top: 14px;
						}
					}
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_title{
					color: #f44332;
				}
				
				.os_pl_div:hover .os_pl_di_contentDiv .os_pl_di_cd_pricing{
					color: #fff;
					border: solid 1px #f44332;
					background: #f44332;
				}
				
				.os_pl_div:hover:after{
					width: 100%;
				}
		
				.os_pl_div::after {
					display: block;
					content: '';
					width: 0;
					border-bottom: 2px solid #f44332;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
</style>
