<template>
	<div>
		<!-- <meta name="viewport" content="width=device-width,initial-scale=1, maximum-scale=1, user-scalable=no"> -->
		<div class="experience" v-if="windowWidth>=1200">
			<div class="ei_titleDiv">
				<div class="ei_td_title">{{$t('aluminumSubstrate.experience.title')}}</div>
				<div class="ei_td_title2">{{$t('aluminumSubstrate.experience.title')}}</div>
			</div>

			<div class="ei_contentDiv">
				<div class="ei_cd_bgDiv">
					<img class="ei_cd_bd_icon" width="120" height="115" src="../../../../assets/img/spe-tag.png" />
					<div class="ei_cd_bd_product">
						<el-image style="width: 100%;height: 100%;" :src="img1" :fit="'contain'"></el-image>
					</div>
					<div class="ei_cd_bd_content">
						<dt class="ei_cd_bd_ct_title">
							{{$t('aluminumSubstrate.experience.content1.title')}}
							<span class="ei_cd_bd_ct_tt_text">{{$t('aluminumSubstrate.experience.content1.text')}}</span>
						</dt>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content1.label1')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content1.label2')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content1.label3')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content1.label4')}}</div>
						</div>
					</div>
				</div>
				
				<div class="ei_cd_bgDiv">
					<img class="ei_cd_bd_icon" width="120" height="115" src="../../../../assets/img/high-tag.png" />
					<div class="ei_cd_bd_product">
						<el-image style="width: 100%;height: 100%;" :src="img2" :fit="'contain'"></el-image>
					</div>
					<div class="ei_cd_bd_content">
						<dt class="ei_cd_bd_ct_title">
							{{$t('aluminumSubstrate.experience.content2.title')}}
						</dt>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content2.label1')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content2.label2')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content2.label3')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content2.label4')}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="experience2" v-if="windowWidth<=1199">
			<div class="ei_titleDiv">
				<div class="ei_td_title">{{$t('aluminumSubstrate.experience.title')}}</div>
				<div class="ei_td_title2">{{$t('aluminumSubstrate.experience.title')}}</div>
			</div>
		
			<div class="ei_contentDiv">
				<div class="ei_cd_bgDiv">
					<img class="ei_cd_bd_icon" width="120" height="115" src="../../../../assets/img/spe-tag.png" />
					<div class="ei_cd_bd_product">
						<el-image style="width: 100%;height: 100%;" :src="img1" :fit="'contain'"></el-image>
					</div>
					<div class="ei_cd_bd_content">
						<dt class="ei_cd_bd_ct_title">
							{{$t('aluminumSubstrate.experience.content1.title')}}
							<span class="ei_cd_bd_ct_tt_text">{{$t('aluminumSubstrate.experience.content1.text')}}</span>
						</dt>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content1.label1')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content1.label2')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content1.label3')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content1.label4')}}</div>
						</div>
					</div>
				</div>
				
				<div class="ei_cd_bgDiv">
					<img class="ei_cd_bd_icon" width="120" height="115" src="../../../../assets/img/high-tag.png" />
					<div class="ei_cd_bd_product">
						<el-image style="width: 100%;height: 100%;" :src="img2" :fit="'contain'"></el-image>
					</div>
					<div class="ei_cd_bd_content">
						<dt class="ei_cd_bd_ct_title">
							{{$t('aluminumSubstrate.experience.content2.title')}}
						</dt>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content2.label1')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content2.label2')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content2.label3')}}</div>
						</div>
						<div class="ei_cd_bd_ct_title2">
							<div class="ei_cd_bd_ct_ti_icon">√</div>
							<div class="ei_cd_bd_ct_ti_text">{{$t('aluminumSubstrate.experience.content2.label4')}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				img1:process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/spe-bg.png',
				img2:process.env.VUE_APP_OSS_URL + '/aluminumSubstrate/high-bg.png',
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.experience {
			padding: 40px 200px;
		
			.ei_titleDiv {
				text-align: center;
				position: relative;
		
				.ei_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
		
				.ei_td_title2 {
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.ei_contentDiv {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-top: 70px;
		
				.ei_cd_bgDiv {
					padding: 1vw;
					background:#f5f5f5;
					width: 49%;
					position: relative;
					box-sizing: border-box;
					border-bottom: 2px solid transparent;
					transition: all 0.3s;
					display: flex;
					align-items: center;
					
					.ei_cd_bd_icon{
						position: absolute;
						top: -8px;
						left: -8px;
						z-index: 3;
					}
					
					.ei_cd_bd_product{
						width: 16vw;
						height: 16vw;
					}
					
					.ei_cd_bd_content{
						padding-left: 1vw;
						
						.ei_cd_bd_ct_title{
							font-size: 1.6vw;
							color: #383838;
							font-weight: 700;
							margin-bottom: 16px;
							
							.ei_cd_bd_ct_tt_text{
								font-size: 0.6vw;
								color: #666666;
								font-weight: normal;
								padding-top: 1.2vw;
							}
						}
						
						.ei_cd_bd_ct_title2{
							padding-top: 0.3vw;
							display: flex;
							align-items: center;
							
							.ei_cd_bd_ct_ti_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 0.3vw;
							}
							
							.ei_cd_bd_ct_ti_text{
								font-size: 0.3vw;
								padding-left: 10px;
							}
						}
						
						.ei_cd_bd_ct_theJumpLink{
							display: flex;
							padding-top: 20px;
							
							.ei_cd_bd_ct_tjl_price{
								background-color: #f44332;
								border: solid 1px #f44332;
								border-radius: 6px 0px 0px 6px;
								font-size: 2vw;
								font-weight: 700;
								color: #fff;
								display: flex;
								justify-content: center;
								align-items: baseline;
								padding: 0.2vw 1vw;
								
								.ei_cd_bd_ct_tjl_pr_yuan{
									font-size: 1vw;
									margin: 0 4px;
								}
							}
							
							.ei_cd_bd_ct_tjl_custom{
								border-radius: 0px 6px 6px 0px;
								border: solid 1px #f44332;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 1vw;
								color: #f44332;
								padding: 0.2vw 1vw;
							}
						}
					}
				}
				
				.ei_cd_bgDiv:hover{
					transform: translateY(-5px);
				}
				
				.ei_cd_bgDiv:hover:after{
					width: 100%;
				}
				
				.ei_cd_bgDiv:after{
					display: block;
					content: '';
					border-bottom: 2px solid #f44332;
					width: 0;
					position: absolute;
					left: 0;
					bottom: -2px;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.experience {
			padding: 40px 200px;
		
			.ei_titleDiv {
				text-align: center;
				position: relative;
		
				.ei_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
		
				.ei_td_title2 {
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.ei_contentDiv {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-top: 70px;
		
				.ei_cd_bgDiv {
					padding: 1vw;
					background:#f5f5f5;
					width: 49%;
					position: relative;
					box-sizing: border-box;
					border-bottom: 2px solid transparent;
					transition: all 0.3s;
					display: flex;
					align-items: center;
					
					.ei_cd_bd_icon{
						position: absolute;
						top: -8px;
						left: -8px;
						z-index: 3;
					}
					
					.ei_cd_bd_product{
						width: 16vw;
						height: 16vw;
					}
					
					.ei_cd_bd_content{
						padding-left: 1vw;
						
						.ei_cd_bd_ct_title{
							font-size: 1.6vw;
							color: #383838;
							font-weight: 700;
							margin-bottom: 16px;
							
							.ei_cd_bd_ct_tt_text{
								font-size: 0.6vw;
								color: #666666;
								font-weight: normal;
								padding-top: 1.2vw;
							}
						}
						
						.ei_cd_bd_ct_title2{
							padding-top: 0.3vw;
							display: flex;
							align-items: center;
							
							.ei_cd_bd_ct_ti_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 0.3vw;
							}
							
							.ei_cd_bd_ct_ti_text{
								font-size: 0.3vw;
								padding-left: 10px;
							}
						}
						
						.ei_cd_bd_ct_theJumpLink{
							display: flex;
							padding-top: 20px;
							
							.ei_cd_bd_ct_tjl_price{
								background-color: #f44332;
								border: solid 1px #f44332;
								border-radius: 6px 0px 0px 6px;
								font-size: 2vw;
								font-weight: 700;
								color: #fff;
								display: flex;
								justify-content: center;
								align-items: baseline;
								padding: 0.2vw 1vw;
								
								.ei_cd_bd_ct_tjl_pr_yuan{
									font-size: 1vw;
									margin: 0 4px;
								}
							}
							
							.ei_cd_bd_ct_tjl_custom{
								border-radius: 0px 6px 6px 0px;
								border: solid 1px #f44332;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 1vw;
								color: #f44332;
								padding: 0.2vw 1vw;
							}
						}
					}
				}
				
				.ei_cd_bgDiv:hover{
					transform: translateY(-5px);
				}
				
				.ei_cd_bgDiv:hover:after{
					width: 100%;
				}
				
				.ei_cd_bgDiv:after{
					display: block;
					content: '';
					border-bottom: 2px solid #f44332;
					width: 0;
					position: absolute;
					left: 0;
					bottom: -2px;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.experience {
			padding: 40px 140px;
		
			.ei_titleDiv {
				text-align: center;
				position: relative;
		
				.ei_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
		
				.ei_td_title2 {
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.ei_contentDiv {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-top: 70px;
		
				.ei_cd_bgDiv {
					padding: 1vw;
					background:#f5f5f5;
					width: 49%;
					position: relative;
					box-sizing: border-box;
					border-bottom: 2px solid transparent;
					transition: all 0.3s;
					display: flex;
					align-items: center;
					
					.ei_cd_bd_icon{
						position: absolute;
						top: -8px;
						left: -8px;
						z-index: 3;
					}
					
					.ei_cd_bd_product{
						width: 16vw;
						height: 16vw;
					}
					
					.ei_cd_bd_content{
						padding-left: 1vw;
						
						.ei_cd_bd_ct_title{
							font-size: 1.6vw;
							color: #383838;
							font-weight: 700;
							margin-bottom: 16px;
							
							.ei_cd_bd_ct_tt_text{
								font-size: 0.6vw;
								color: #666666;
								font-weight: normal;
								padding-top: 1.2vw;
							}
						}
						
						.ei_cd_bd_ct_title2{
							padding-top: 0.3vw;
							display: flex;
							align-items: center;
							
							.ei_cd_bd_ct_ti_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 0.3vw;
							}
							
							.ei_cd_bd_ct_ti_text{
								font-size: 0.3vw;
								padding-left: 10px;
							}
						}
						
						.ei_cd_bd_ct_theJumpLink{
							display: flex;
							padding-top: 20px;
							
							.ei_cd_bd_ct_tjl_price{
								background-color: #f44332;
								border: solid 1px #f44332;
								border-radius: 6px 0px 0px 6px;
								font-size: 2vw;
								font-weight: 700;
								color: #fff;
								display: flex;
								justify-content: center;
								align-items: baseline;
								padding: 0.2vw 1vw;
								
								.ei_cd_bd_ct_tjl_pr_yuan{
									font-size: 1vw;
									margin: 0 4px;
								}
							}
							
							.ei_cd_bd_ct_tjl_custom{
								border-radius: 0px 6px 6px 0px;
								border: solid 1px #f44332;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 1vw;
								color: #f44332;
								padding: 0.2vw 1vw;
							}
						}
					}
				}
				
				.ei_cd_bgDiv:hover{
					transform: translateY(-5px);
				}
				
				.ei_cd_bgDiv:hover:after{
					width: 100%;
				}
				
				.ei_cd_bgDiv:after{
					display: block;
					content: '';
					border-bottom: 2px solid #f44332;
					width: 0;
					position: absolute;
					left: 0;
					bottom: -2px;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.experience {
			padding: 40px 30px;
		
			.ei_titleDiv {
				text-align: center;
				position: relative;
		
				.ei_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
		
				.ei_td_title2 {
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.ei_contentDiv {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-top: 70px;
		
				.ei_cd_bgDiv {
					padding: 1vw;
					background:#f5f5f5;
					width: 49%;
					position: relative;
					box-sizing: border-box;
					border-bottom: 2px solid transparent;
					transition: all 0.3s;
					display: flex;
					align-items: center;
					
					.ei_cd_bd_icon{
						position: absolute;
						top: -8px;
						left: -8px;
						z-index: 3;
					}
					
					.ei_cd_bd_product{
						width: 16vw;
						height: 16vw;
					}
					
					.ei_cd_bd_content{
						padding-left: 1vw;
						
						.ei_cd_bd_ct_title{
							font-size: 1.6vw;
							color: #383838;
							font-weight: 700;
							margin-bottom: 16px;
							
							.ei_cd_bd_ct_tt_text{
								font-size: 0.6vw;
								color: #666666;
								font-weight: normal;
								padding-top: 1.2vw;
							}
						}
						
						.ei_cd_bd_ct_title2{
							padding-top: 0.3vw;
							display: flex;
							align-items: center;
							
							.ei_cd_bd_ct_ti_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 0.3vw;
							}
							
							.ei_cd_bd_ct_ti_text{
								font-size: 0.3vw;
								padding-left: 10px;
							}
						}
						
						.ei_cd_bd_ct_theJumpLink{
							display: flex;
							padding-top: 20px;
							
							.ei_cd_bd_ct_tjl_price{
								background-color: #f44332;
								border: solid 1px #f44332;
								border-radius: 6px 0px 0px 6px;
								font-size: 2vw;
								font-weight: 700;
								color: #fff;
								display: flex;
								justify-content: center;
								align-items: baseline;
								padding: 0.2vw 1vw;
								
								.ei_cd_bd_ct_tjl_pr_yuan{
									font-size: 1vw;
									margin: 0 4px;
								}
							}
							
							.ei_cd_bd_ct_tjl_custom{
								border-radius: 0px 6px 6px 0px;
								border: solid 1px #f44332;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 1vw;
								color: #f44332;
								padding: 0.2vw 1vw;
							}
						}
					}
				}
				
				.ei_cd_bgDiv:hover{
					transform: translateY(-5px);
				}
				
				.ei_cd_bgDiv:hover:after{
					width: 100%;
				}
				
				.ei_cd_bgDiv:after{
					display: block;
					content: '';
					border-bottom: 2px solid #f44332;
					width: 0;
					position: absolute;
					left: 0;
					bottom: -2px;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.experience2 {
			margin-top: 60px;
			padding: 40px 30px;
		
			.ei_titleDiv {
				text-align: center;
				position: relative;
		
				.ei_td_title {
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
		
				.ei_td_title2 {
					font-size: 29px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.ei_contentDiv {
				width: 100%;
				justify-content: space-between;
				margin-top: 70px;
		
				.ei_cd_bgDiv {
					padding: 1vw;
					background:#f5f5f5;
					margin-bottom: 20px;
					width: 100%;
					position: relative;
					box-sizing: border-box;
					border-bottom: 2px solid transparent;
					transition: all 0.3s;
					display: flex;
					align-items: center;
					
					.ei_cd_bd_icon{
						position: absolute;
						top: -8px;
						left: -8px;
						z-index: 3;
					}
					
					.ei_cd_bd_product{
						width: 16vw;
						height: 16vw;
					}
					
					.ei_cd_bd_content{
						padding-left: 1vw;
						
						.ei_cd_bd_ct_title{
							font-size: 1.6vw;
							color: #383838;
							font-weight: 700;
							margin-bottom: 16px;
							
							.ei_cd_bd_ct_tt_text{
								font-size: 0.6vw;
								color: #666666;
								font-weight: normal;
								padding-top: 1.2vw;
							}
						}
						
						.ei_cd_bd_ct_title2{
							padding-top: 0.3vw;
							display: flex;
							align-items: center;
							
							.ei_cd_bd_ct_ti_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 0.3vw;
							}
							
							.ei_cd_bd_ct_ti_text{
								font-size: 0.3vw;
								padding-left: 10px;
							}
						}
						
						.ei_cd_bd_ct_theJumpLink{
							display: flex;
							padding-top: 20px;
							
							.ei_cd_bd_ct_tjl_price{
								background-color: #f44332;
								border: solid 1px #f44332;
								border-radius: 6px 0px 0px 6px;
								font-size: 2vw;
								font-weight: 700;
								color: #fff;
								display: flex;
								justify-content: center;
								align-items: baseline;
								padding: 0.2vw 1vw;
								
								.ei_cd_bd_ct_tjl_pr_yuan{
									font-size: 1vw;
									margin: 0 4px;
								}
							}
							
							.ei_cd_bd_ct_tjl_custom{
								border-radius: 0px 6px 6px 0px;
								border: solid 1px #f44332;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 1vw;
								color: #f44332;
								padding: 0.2vw 1vw;
							}
						}
					}
				}
				
				.ei_cd_bgDiv:hover{
					transform: translateY(-5px);
				}
				
				.ei_cd_bgDiv:hover:after{
					width: 100%;
				}
				
				.ei_cd_bgDiv:after{
					display: block;
					content: '';
					border-bottom: 2px solid #f44332;
					width: 0;
					position: absolute;
					left: 0;
					bottom: -2px;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.experience2 {
			margin-top: 60px;
			padding: 40px 30px;
		
			.ei_titleDiv {
				text-align: center;
				position: relative;
		
				.ei_td_title {
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
					z-index: 3;
					position: relative;
				}
		
				.ei_td_title2 {
					font-size: 40px;
					color: #f4f3f3;
					line-height: 28px;
					width: 100%;
					text-align: center;
					font-weight: bold;
					position: absolute;
					bottom: -5px;
					z-index: 2;
				}
			}
		
			.ei_contentDiv {
				width: 100%;
				justify-content: space-between;
				margin-top: 70px;
		
				.ei_cd_bgDiv {
					padding: 1vw;
					background:#f5f5f5;
					margin-bottom: 20px;
					width: 100%;
					position: relative;
					box-sizing: border-box;
					border-bottom: 2px solid transparent;
					transition: all 0.3s;
					display: flex;
					align-items: center;
					
					.ei_cd_bd_icon{
						position: absolute;
						top: -8px;
						left: -8px;
						z-index: 3;
					}
					
					.ei_cd_bd_product{
						width: 150px;
						height: 150px;
					}
					
					.ei_cd_bd_content{
						padding-left: 10px;
						
						.ei_cd_bd_ct_title{
							font-size: 16px;
							color: #383838;
							font-weight: 700;
							margin-bottom: 16px;
							
							.ei_cd_bd_ct_tt_text{
								font-size: 12px;
								color: #666666;
								font-weight: normal;
								padding-top: 1.2vw;
							}
						}
						
						.ei_cd_bd_ct_title2{
							padding-top: 0.3vw;
							display: flex;
							align-items: center;
							
							.ei_cd_bd_ct_ti_icon{
								color: #e64638;
								border: 1px solid #e64638;
								padding: 1px 5px;
								border-radius: 40px;
								font-size: 12px;
							}
							
							.ei_cd_bd_ct_ti_text{
								font-size: 12px;
								padding-left: 10px;
							}
						}
						
						.ei_cd_bd_ct_theJumpLink{
							display: flex;
							padding-top: 20px;
							
							.ei_cd_bd_ct_tjl_price{
								background-color: #f44332;
								border: solid 1px #f44332;
								border-radius: 6px 0px 0px 6px;
								font-size: 2vw;
								font-weight: 700;
								color: #fff;
								display: flex;
								justify-content: center;
								align-items: baseline;
								padding: 0.2vw 1vw;
								
								.ei_cd_bd_ct_tjl_pr_yuan{
									font-size: 1vw;
									margin: 0 4px;
								}
							}
							
							.ei_cd_bd_ct_tjl_custom{
								border-radius: 0px 6px 6px 0px;
								border: solid 1px #f44332;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 1vw;
								color: #f44332;
								padding: 0.2vw 1vw;
							}
						}
					}
				}
				
				.ei_cd_bgDiv:hover{
					transform: translateY(-5px);
				}
				
				.ei_cd_bgDiv:hover:after{
					width: 100%;
				}
				
				.ei_cd_bgDiv:after{
					display: block;
					content: '';
					border-bottom: 2px solid #f44332;
					width: 0;
					position: absolute;
					left: 0;
					bottom: -2px;
					z-index: 2;
					transition: all 0.3s linear;
				}
			}
		}
	}
	
</style>
