<template>
	<div>
		<div style="padding: 40px 200px;background: #333333;">
			<div style="text-align: center;">
				<div style="font-size: 34px;text-align: center;font-weight: bold;line-height: 58px;color: #fff;">质量认证体系</div>
				<div style="font-size: 18px;color: #fff;line-height: 28px;">格物思获得设计发明类、实用新型类专利和软件著作权共计60余项</div>
				<div style="display: flex;justify-content: center;">
					<div style="color: red;padding: 2px 20px;border: 1px solid red;border-radius: 40px;margin: 20px 10px;" v-for="(item,index) in lable" :key="index">{{item}}</div>
				</div>
				<!-- <el-image style="width: 100%;margin-top: 40px;" :src="require('@/assets/img/why-choose1@img.png')" :fit="'fill'"></el-image> -->
				<div style="display: flex;flex-wrap: wrap;align-items: center;justify-content: center;">
					<div style="width: 16vw;padding: 0 10px;">
						<el-image style="width: 100%;margin-top: 40px;" :src="require('@/assets/img/certificate/esc.png')" :fit="'fill'"></el-image>
					</div>
					<div style="width: 16vw;padding: 0 10px;">
						<el-image style="width: 100%;margin-top: 40px;" :src="require('@/assets/img/certificate/henlv1.png')" :fit="'fill'"></el-image>
					</div>
					<div style="width: 16vw;padding: 0 10px;">
						<el-image style="width: 100%;margin-top: 40px;" :src="require('@/assets/img/certificate/ISO.png')" :fit="'fill'"></el-image>
					</div>
					<div style="width: 16vw;padding: 0 10px;">
						<el-image style="width: 100%;margin-top: 40px;" :src="require('@/assets/img/certificate/nkt.png')" :fit="'fill'"></el-image>
					</div>
					<div style="width: 16vw;padding: 0 10px;">
						<el-image style="width: 100%;margin-top: 40px;" :src="require('@/assets/img/certificate/qlmos.png')" :fit="'fill'"></el-image>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				lable:['"专精特新"企业','高新技术企业','IATF 16949汽车质量管理体系认证'],
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>