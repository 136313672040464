<template>
	<div id="aluminumSubstrate">
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<right v-if="windowWidth>=1200"></right>
		<experience :windowWidth="windowWidth" :windowHeight="windowHeight"></experience>
		<optional-sheet :windowWidth="windowWidth" :windowHeight="windowHeight"></optional-sheet>
		<!-- <reliability></reliability>
		<process-capability></process-capability> -->
		<!-- <certificate></certificate> -->
		<bottom v-if="windowWidth>=1200"></bottom>
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import experience from './components/aluminumSubstrate/experience.vue';
	import optionalSheet from './components/aluminumSubstrate/optionalSheet.vue';
	import reliability from './components/aluminumSubstrate/reliability.vue';
	import processCapability from './components/aluminumSubstrate/processCapability.vue';
	import certificate from './components/aluminumSubstrate/certificate.vue';
	import bottom from '@/components/bottom/index.vue';
	export default {
		components:{
			top,
			experience,
			optionalSheet,
			reliability,
			processCapability,
			certificate,
			bottom,
			right
		},
		data() {
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less">
	
</style>