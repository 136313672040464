<template>
	<div>
		<div style="padding: 40px 200px;">
			<div style="text-align: center;position: relative;">
				<div style="font-size: 34px;text-align: center;font-weight: bold;line-height: 58px;color: #333333;z-index: 3;position: relative;">制程能力</div>
				<div style="font-size: 40px;color: #f4f3f3;line-height: 28px;width: 100%;text-align: center;font-weight: bold;position: absolute;bottom: -5px;z-index: 2;">制程能力</div>
			</div>
		</div>
		
		<div style="padding: 40px 200px;" :style="'background-image:' + `url(${require('@/assets/img/make-bg.png')})`">
			<div style="background: #fff;padding: 20px;border-right:5px solid red;border-bottom: 5px solid red;position: relative;">
				<div style="box-sizing: border-box;display: flex;width: 100%;flex-wrap: wrap;">
					<div style="width: 24%;margin-bottom: 1vw;" v-for="(item,index) in list" :key="index">
						<div style="font-weight: bold;font-size: 1vw;color: red;line-height: 30px;">{{item.name}}</div>
						<div style="color: #333333;line-height: 30px;" v-for="(item2,index2) in item.text" :key="index2">
							{{item2}}
						</div>
					</div>
				</div>
				<el-image style="width: 10vw;position: absolute;bottom: 0;right: 0;" :src="require('@/assets/img/make-example.png')" :fit="'cover'"></el-image>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				list:[{
					name:"层数",
					text:["1-4层"]
				},
				{
					name:"成品板厚",
					text:["0.6~4.0mm"]
				},
				{
					name:"导热系数",
					text:["0.5-385w/m.K"]
				},
				{
					name:"击穿电压",
					text:["2.0-4.0KV"]
				},
				{
					name:"铜箔厚度",
					text:["Hoz—4oz"]
				},
				{
					name:"阻燃等级",
					text:["94V0"]
				},
				{
					name:"CTI",
					text:["250-600V"]
				},
				{
					name:"表面处理",
					text:["无铅喷锡、有铅喷锡、沉金、OSP等"]
				},
				{
					name:"成型方式",
					text:["激光切割：±0.05mm","锣板成型：±0.15mm"]
				},
				{
					name:"最小孔径",
					text:["0.80mm (混压min0.30mm)"]
				},
				{
					name:"最大/最小成品尺寸",
					text:["最大尺寸：1170mmX427mm","最小尺寸：10mmX10mm"]
				},
				{
					name:"符合认证",
					text:["IPC-6012 Class2","IPC-6012 Class3","IATF 16949","ISO 13485"]
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		},
	}
</script>

<style lang="less">
	
</style>