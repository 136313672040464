<template>
	<div>
		<div class="reliability">
			<div class="re_titleDiv">
				<div class="re_td_title">高可靠制板厂家</div>
				<div class="re_td_title2">高可靠制板厂家</div>
			</div>
			
			<div class="re_contentDiv">
				<div class="re_ct_div" v-for="(item,index) in list" :key="index">
					<div>
						<img class="re_ct_div_img" :src="item.img" />
					</div>
					<div class="re_ct_div_content">
						<a class="re_ct_div_ct_title">{{item.title}}</a>
						<div class="re_ct_div_ct_text">{{item.text}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list:[{
					title:'工厂优势',
					img:require('@/assets/img/vender-img1.png'),
					text:'样板12小时，批量5天'
				},
				{
					title:'速度优势',
					img:require('@/assets/img/vender-img2.png'),
					text:'近10年高导金属基板生产经验源头工厂 直接生产'
				},
				{
					title:'品牌优势',
					img:require('@/assets/img/vender-img3.png'),
					text:'完善的PCBA产业链一站式服务实力并存 值得您信赖'
				},
				{
					title:'品质优势',
					img:require('@/assets/img/vender-img4.png'),
					text:'LDI曝光机,真空蚀刻机;AOI 线路自动检测,文字自动喷印,激光成型'
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	.reliability{
		padding: 40px 200px;
		
		.re_titleDiv{
			text-align: center;
			position: relative;
			
			.re_td_title{
				font-size: 34px;
				text-align: center;
				font-weight: bold;
				line-height: 58px;
				color: #333333;
				z-index: 3;
				position: relative;
			}
			
			.re_td_title2{
				font-size: 40px;
				color: #f4f3f3;
				line-height: 28px;
				width: 100%;
				text-align: center;
				font-weight: bold;
				position: absolute;
				bottom: -5px;
				z-index: 2;
			}
		}
		
		.re_contentDiv{
			display: flex;
			padding-top: 70px;
			justify-content: space-between;
			width: 100%;
			
			.re_ct_div{
				width: 24%;
				
				.re_ct_div_img{
					width: 100%;
					height: 220px;
					overflow: hidden;
				}
				
				.re_ct_div_content{
					background: #f5f5f5;
					position: relative;
					margin-top: 5px;
					
					.re_ct_div_ct_title{
						background-color: #ea5749;
						border-radius: 0px 0px 12px 0px;
						font-size: 1vw;
						font-weight: 700;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff;
						display: flex;
						margin-right: 10vw;
						padding: 5px 0;
					}
					
					.re_ct_div_ct_text{
						padding: 10px 20px 30px 20px;
						height: 100px;
						font-size: 0.8vw;
					}
				}
			}
		}
	}
</style>